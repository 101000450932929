import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vClickOutside from 'click-outside-vue3';

const app = createApp(App);
app.config.globalProperties.translate = window.lang;
app.use(vClickOutside);
app.use(VueAxios, axios);
app.mount('#app');
